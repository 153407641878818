<template>
  <div class="UploadItem">
    <v-card tile class="item mb-4">
      <v-card-title class="pb-0">{{
        item.fridgeDisplayType.name
      }}</v-card-title>
      <v-card-text>
        <p>{{ item.fridgeDisplayType.size }}</p>
        <div class="d-flex">
          <div class="accepted">
            <v-sheet dark elevation="4" rounded class="mr-4 overflow-hidden">
              <a
                href=""
                @click.prevent="enlarge(item.fridgeDisplayType.sampleImage)"
              >
                <v-img
                  style="border: 2px solid #999; border-radius: 3px"
                  width="100"
                  height="100"
                  :src="buildImage"
                ></v-img>
              </a>
            </v-sheet>
            <br />
            <p><small>Elvárt megjelenés</small></p>
          </div>

          <div class="preview" v-if="capturedFile">
            <v-sheet elevation="4" rounded class="overflow-hidden mr-4">
              <v-img max-width="100" height="100" :src="capturedPreviewUrl">
              </v-img>
            </v-sheet>
            <v-btn
              @click="handleRemove(item)"
              elevation="3"
              class="cornerButton"
              small
              fab
              ><v-icon large>mdi-close</v-icon></v-btn
            >
            <br />
            <p><small>Feltöltendő Kep</small></p>
          </div>
          <div class="upload" v-if="!capturedFile">
            <v-btn
              elevation="3"
              @click="triggerImageUpload(item)"
              width="100"
              height="100"
              dark
              ><v-icon x-large>mdi-plus</v-icon></v-btn
            >
            <input
              style="display: none"
              type="file"
              ref="fileUpload"
              capture="environment"
              accept="image/*"
              @change="handleCamera"
            />
            <br />
            <br />
            <p><small>Feltöltendő Kép</small></p>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isPreview">
      <v-img v-if="isPreview" :src="buildImage"></v-img>
    </v-dialog>
  </div>
</template>

<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import { IMAGE_BASE } from "../util/constants.js";

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
    file: {
      type: File,
      required: false,
      default: null,
    },
  },
  name: "UploadItem",

  data() {
    return {
      imageToUpload: null,
      activeImageUrl: "",
      dialog: false,
      capturedFile: null,
      isPreview: false,
    };
  },
  methods: {
    enlarge(_image) {
      this.isPreview = true;
    },
    triggerImageUpload(item) {
      this.imageToUpload = null;
      this.$refs.fileUpload.click();
    },
    handleCamera(event) {
      this.capturedFile = null;
      this.capturedFile = event.target.files[0];
      this.$emit("update:file", this.capturedFile);
    },
    handleRemove(item) {
      this.$emit("update:file", null);
      this.capturedFile = null;
    },
  },
  computed: {
    buildImage() {
      let name = this.item.fridgeDisplayType.sampleImage.filename;
      let folder = this.item.fridgeDisplayType.sampleImage.dirPath.substring(1);
      return `${IMAGE_BASE}${folder}${name}`;
    },
    uploadPreviewUrl() {
      return URL.createObjectURL(this.imageToUpload);
    },
    capturedPreviewUrl() {
      return URL.createObjectURL(this.capturedFile);
    },
  },
};
</script>
<style scoped>
.preview {
  position: relative;
}

.preview .cornerButton {
  position: absolute;
  top: 30px;
  left: 30px;
}

.item {
  border-bottom: 2px solid var(--red);
}
</style>
