<template>
  <div class="Upload">
    <UploadItem
      v-for="item in items"
      :key="item.id"
      :item="item"
      :file.sync="item.file"
    ></UploadItem>
    <div class="no-fridge" v-if="items.length < 1">
      <v-card dark>
        <v-card-title>Nincs elérhető kihelyezés?</v-card-title>
        <v-card-text>
          <v-btn
            x-large
            color="primary"
            @click="$router.push('/huto-beallitasok')"
            >HOZZÁADÁS</v-btn
          >
        </v-card-text>
      </v-card>
    </div>
    <v-container
      style="
        position: fixed;
        bottom: 0;
        background-color: rgba(255, 255, 255, 1);
        text-align: center;
      "
    >
      <v-btn
        class="center fp pl-8 pr-8"
        color="secondary"
        :disabled="!isValid"
        rounded
        @click="submit()"
        >MENTÉS <v-icon class="ml-2">mdi-check</v-icon></v-btn
      >
    </v-container>
    <v-dialog v-model="dialog" class="dialog">
      <div class="scope">
        <v-img :src="activeImageUrl"></v-img>
        <v-btn
          elevation="8"
          small
          dark
          :disabled="!isValid"
          @click="dialog = false"
          class="close"
          fab
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import messageBus from "../messageBus.js";
import UploadItem from "../components/UploadItem.vue";
import { API_BASE } from "../util/constants";
import { getMultipartHeader, toQueryString } from "../util/functions.js";
import moment from "moment";
export default {
  name: "Upload",
  components: { UploadItem },
  created() {},
  mounted() {
    this.getList();
  },
  data() {
    return {
      images: [],
      items: [],
      dialog: false,
      activeImageUrl: null,
      currentPage: 1,
    };
  },
  computed: {
    isValid() {
      return this.items.every((item) => item.file !== null);
    },
  },
  methods: {
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `client/fridge-displays`,
          data: null,
        })
        .then((res) => {
          if (res) {
            res.forEach((item, index) => {
              res[index].file = null;
            });
            this.items = res;
          }
        });
    },
    async submit(item) {
      let formdata = new FormData();
      console.log(this.capturedFile);
      this.items.forEach((item) => {
        formdata.append("images", item.file, item.file.name);
        formdata.append("fridgeDisplayTypeIds", item.fridgeDisplayType.id);
      });

      const requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };
      try {
        eventBus.$emit("LOAD_ON");

        let response = await fetch(`${API_BASE}client/uploads`, requestOptions);

        if (response.ok) {
          const jsonResponse = await response.json();
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.$store.dispatch("setUploadLock", Date.now());
          this.$router.push("/feltoltesek");
        } else {
          messageBus.$emit("onNewMessage", {
            type: "error",
            label: this.$t("message.error"),
          });
        }
      } catch (error) {
        messageBus.$emit("onNewMessage", {
          type: "error",
          label: this.$t("message.error"),
        });
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
  },
};
</script>
<style scoped>
.scope {
  position: relative;
}
.v-dialog .close {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>
